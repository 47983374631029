import React, { useEffect, useMemo } from 'react';
import { Global, GlobalProps } from '@emotion/react';
import { useTheme } from '@cvent/carina/components/ThemeProvider';
import { GlobalTypography } from '@cvent/carina/components/Typography';

export function GlobalStyles(): JSX.Element {
  const theme = useTheme();

  const globalStyles = useMemo<GlobalProps['styles']>(
    () => ({
      '*': { boxSizing: 'border-box' }
      /* Add global styles here. Example:
          html: { height: '100%' } */
    }),
    []
  );

  useEffect(() => {
    // Log the Carina theme object for reference
    if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.info(
        '%cCarina Theme Reference',
        `
      padding: .25rem .5rem;
      margin: .5rem 0;
      color: ${theme.font.color.brand.base};
      font-family: ${theme.font.base.family};
      font-size: ${theme.font.base.size.s};
      background: ${theme.backgroundColor.base};
      border-left: 4px solid ${theme.backgroundColor.accent.fill.surface};
    `,
        theme
      );
    }
  }, [theme]);

  return (
    <>
      <GlobalTypography />
      <Global styles={globalStyles} />
    </>
  );
}

export default GlobalStyles;
