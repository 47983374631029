"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExperimentsByParticipantIdQuery = exports.getExperimentsByUserIdQuery = exports.getTopNavExperimentsQuery = exports.getExperimentsQuery = void 0;
var client_1 = require("@apollo/client");
/**
 * We don't want to expose hash manipulation to public mutable environment.
 * @deprecated
 * <p> Use {@link #getExperimentsByUserIdQuery()} or {@link #getExperimentsByParticipantIdQuery()} instead.
 */
exports.getExperimentsQuery = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getExperimentsQuery($experimentName: String, $hashId: Int) {\n    getExperiment(experimentName: $experimentName, hashId: $hashId) {\n      variantId: id\n      metadata\n      experimentName\n    }\n  }\n"], ["\n  query getExperimentsQuery($experimentName: String, $hashId: Int) {\n    getExperiment(experimentName: $experimentName, hashId: $hashId) {\n      variantId: id\n      metadata\n      experimentName\n    }\n  }\n"])));
exports.getTopNavExperimentsQuery = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getTopNavExperiments {\n    lighthouse: getExperimentByUserId(experimentName: \"passkey_lighthouse_navigation\") {\n      variantId: id\n      metadata\n      experimentName\n    }\n    findReservation: getExperimentByParticipantId(experimentName: \"passkey_callcenter_find_reservation\") {\n      variantId: id\n      metadata\n      experimentName\n    }\n    myCventIntegration: getExperimentByParticipantId(experimentName: \"passkey_my_cvent_integration\") {\n      variantId: id\n      metadata\n      experimentName\n    }\n    myCventForSsoUsers: getExperimentByParticipantId(experimentName: \"passkey_enable_my_cvent_for_sso_users\") {\n      variantId: id\n      metadata\n      experimentName\n    }\n    abNotifications: getExperimentByParticipantId(experimentName: \"passkey_ab_notifications\") {\n      variantId: id\n      metadata\n      experimentName\n    }\n  }\n"], ["\n  query getTopNavExperiments {\n    lighthouse: getExperimentByUserId(experimentName: \"passkey_lighthouse_navigation\") {\n      variantId: id\n      metadata\n      experimentName\n    }\n    findReservation: getExperimentByParticipantId(experimentName: \"passkey_callcenter_find_reservation\") {\n      variantId: id\n      metadata\n      experimentName\n    }\n    myCventIntegration: getExperimentByParticipantId(experimentName: \"passkey_my_cvent_integration\") {\n      variantId: id\n      metadata\n      experimentName\n    }\n    myCventForSsoUsers: getExperimentByParticipantId(experimentName: \"passkey_enable_my_cvent_for_sso_users\") {\n      variantId: id\n      metadata\n      experimentName\n    }\n    abNotifications: getExperimentByParticipantId(experimentName: \"passkey_ab_notifications\") {\n      variantId: id\n      metadata\n      experimentName\n    }\n  }\n"])));
exports.getExperimentsByUserIdQuery = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query getExperimentByUserId($experimentName: String) {\n    getExperimentByUserId(experimentName: $experimentName) {\n      variantId: id\n      metadata\n      experimentName\n    }\n  }\n"], ["\n  query getExperimentByUserId($experimentName: String) {\n    getExperimentByUserId(experimentName: $experimentName) {\n      variantId: id\n      metadata\n      experimentName\n    }\n  }\n"])));
exports.getExperimentsByParticipantIdQuery = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query getExperimentByParticipantId($experimentName: String) {\n    getExperimentByParticipantId(experimentName: $experimentName) {\n      variantId: id\n      metadata\n      experimentName\n    }\n  }\n"], ["\n  query getExperimentByParticipantId($experimentName: String) {\n    getExperimentByParticipantId(experimentName: $experimentName) {\n      variantId: id\n      metadata\n      experimentName\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
