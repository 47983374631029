import { gql } from '@apollo/client';

export const getMagicLinkQuery = gql`
  query getMagicLinkQuery($productId: String, $state: String) {
    magicLink(productId: $productId, state: $state) {
      link
    }
  }
`;

export const getMagicLinkByPlatformUserIdQuery = gql`
  query getMagicLinkByPlatformUserIdQuery($platformUserId: String, $productId: String) {
    magicLinkByPlatformUserId(platformUserId: $platformUserId, productId: $productId) {
      link
    }
  }
`;
