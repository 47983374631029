import { PlatformUserAssoc } from '@cvent/passkey-admin-model/types';

/**
 * Determines whether to display MyCvent menu items based on several conditions.
 *
 * @param {PlatformUserAssoc} platformUserAssoc - The platform user association object presents.
 * @param {boolean} ssoEnabled - Indicates whether Single Sign-On (SSO) is enabled for passkey user.
 * @param {boolean} isMyCventEnabled - Indicates whether MyCvent is enabled.
 * @param {boolean} isMyCventEnabledForSsoUsers - Indicates whether MyCvent is enabled for SSO users.
 *
 * @returns {boolean} Returns true if MyCvent menu items should be displayed, otherwise false.
 * My Cvent menu items should be displayed if MyCvent feature is enabled and the platform user association
 * object is present. If SSO is enabled, MyCvent menu items should be displayed only if MyCvent
 * is enabled for SSO users.
 */
export const displayMyCventMenuItems = (
  platformUserAssoc: PlatformUserAssoc,
  ssoEnabled: boolean,
  isMyCventEnabled: boolean,
  isMyCventEnabledForSsoUsers: boolean
): boolean => {
  const isDisplayed = isMyCventEnabled && !!platformUserAssoc;

  if (ssoEnabled) {
    return isDisplayed && isMyCventEnabledForSsoUsers;
  }
  return isDisplayed;
};
