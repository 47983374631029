import { gql } from '@apollo/client';

export const fetchMetadata = gql`
  query fetchMetadata {
    fetchMetadata {
      authorization {
        metadata {
          userId
          participantId
          username
          firstName
          lastName
          emailAddress
          objectTypeId
          userTypeId
          reportingLevel
          hasSisterHotels
          userStatus
          invalidLoginCount
          disabled
          customerFlags
          creditCardInfoViewable
          testUser
          apiUser
          adminUser
          participantType
          accountMappingId
          UserStub
        }
      }
      accessToken
    }
  }
`;
