import { datadogRum } from '@datadog/browser-rum';
import { getDataDogOptions } from './getDatadogConfig';

let initialized = false;

/**
 * Overriding NX initRum for now since the NX function does not take the latest configuration.
 */
export const initializeRUM = (): void => {
  if (typeof window !== 'undefined' && !initialized) {
    const configuration = getDataDogOptions();
    const { applicationId, clientToken } = configuration;
    if (applicationId && clientToken) {
      // Initialize RUM
      datadogRum.init(configuration);
    }
    initialized = true;
  }
};
