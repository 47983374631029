/**
 * Used to decode from b64 coded value to the original String
 * @param str
 */
export const decode = (str: string): string => Buffer.from(str, 'base64').toString('binary');

/**
 * Used to return decoded base 64 to number .
 * @param base64String
 */
export const decodeCursor = (base64String: string): number => parseInt(decode(base64String), 10);

/**
 * used to encode string to b64
 * @param str
 */
export const encode = (str: string): string => Buffer.from(str, 'binary').toString('base64');

/**
 * used to encode number to b64
 * @param str
 */
export const encodeNumber = (number: number): string => encode(number?.toString());
