"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.manageSessionMutation = void 0;
var client_1 = require("@apollo/client");
exports.manageSessionMutation = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation manageSession($rdSessionId: ID, $action: String) {\n    manageSession(rdSessionId: $rdSessionId, action: $action) {\n      responseStatus\n    }\n  }\n"], ["\n  mutation manageSession($rdSessionId: ID, $action: String) {\n    manageSession(rdSessionId: $rdSessionId, action: $action) {\n      responseStatus\n    }\n  }\n"])));
var templateObject_1;
