import React, { ReactElement } from 'react';
import type { ReactNode } from 'react';
import { Page as TemplatePage, ContentArea, Main, Body, Header } from '@cvent/carina/components/Templates';
import { UniversalSessionHandler } from '@components/userSession/UniversalSessionHandler';
import Head from 'next/head';
import { useTranslate } from 'nucleus-text';
import { useRouter } from 'next/router';
import { TopNavigation } from './TopNavigation';

export function BasePage({ children }: { children: ReactNode }): ReactElement {
  const router = useRouter();
  const { pathname } = router;

  const { translate } = useTranslate();
  return (
    <TemplatePage>
      <UniversalSessionHandler>
        <Head>
          <title>{translate('_passkey_admin_basepage_header')}</title>
        </Head>
        <Main as="div">
          <Header>
            <TopNavigation currentPathname={pathname} />
          </Header>
          <Body>
            <ContentArea>
              <div
                css={{
                  flexGrow: 1,
                  overflowY: 'auto'
                }}
              >
                {children}
              </div>
            </ContentArea>
          </Body>
        </Main>
      </UniversalSessionHandler>
    </TemplatePage>
  );
}
