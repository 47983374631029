import { useQuery } from '@apollo/client';
import {
  getExperimentsByParticipantIdQuery,
  getExperimentsByUserIdQuery
} from '@cvent/passkey-admin-model/operations/rdk2/experiment';
import { GraphClient } from '@utils/apolloClientUtils';

const NAV_EXPERIMENT_NAME = 'passkey_lighthouse_navigation';
export const SBG_EXPERIMENT_NAME = 'passkey_lighthouse_sbg';
const ROOM_CATEGORIES_EXPERIMENT_NAME = 'passkey_room_categories';
const MY_CVENT_EXPERIMENT_NAME = 'passkey_my_cvent_integration';
const CVENT_COMMUNITY_EXPERIMENT_NAME = 'passkey_cvent_community_integration';
const HELP_AND_SUPPORT_EXPERIMENT_NAME = 'passkey_help_and_support_integration';
export const MANUAL_APPROVAL_EXPERIMENT_NAME = 'passkey_autoblock_manual_approval';
export const FIND_RESERVATION_EXPERIMENT_NAME = 'passkey_callcenter_find_reservation';
export const SWITCH_TO_CLASSIC_BANNER = 'passkey_switch_to_classic_banner';
export const EVENT_HOTELS_PAGE_EXPERIMENT = 'passkey_event_hotels_page';
export const EVENT_OVERVIEW_EXPERIMENT = 'passkey_event_overview';
export const MY_CVENT_SSO_USERS_EXPERIMENT_NAME = 'passkey_enable_my_cvent_for_sso_users';
export const AB_NOTIFICATIONS_EXPERIMENT_NAME = 'passkey_ab_notifications';

/**
 * Checks if the given experiment is enabled.
 */
export const isExperimentEnabled = (experiment): boolean => {
  return experiment?.variantId === 1;
};

/**
 * Be aware that this experiment is an on/off switch.  It can
 * only have two states.  Variant ID 0 evaluates to false and
 * all other variants (1,2,3 etc.) will evaluate to true.
 * <p>
 * Experiment name - passkey_lighthouse_navigation
 */
export const useLighthouseNavigationExperiment = (lighthouseData?): boolean => {
  let useData;
  if (lighthouseData) {
    useData = lighthouseData;
  } else {
    const { data } = useQuery(getExperimentsByUserIdQuery, {
      variables: {
        experimentName: NAV_EXPERIMENT_NAME
      },
      ssr: false,
      fetchPolicy: 'no-cache',
      context: { clientName: GraphClient.PASSKEY_RDK2 }
    });
    useData = data?.getExperimentByUserId;
  }

  return useData?.variantId === 1;
};

/**
 * This experiment uses the metadata object to derive it's state.
 * <p>
 * Experiment name - passkey_lighthouse_navigation
 */
export const useLighthouseEventHomePageExperimentField = (): boolean => {
  const { data } = useQuery(getExperimentsByUserIdQuery, {
    variables: {
      experimentName: NAV_EXPERIMENT_NAME,
      context: { clientName: GraphClient.PASSKEY_RDK2 }
    },
    ssr: false,
    fetchPolicy: 'cache-first'
  });

  return data?.getExperimentByUserId?.metadata?.lighthouseEventHomePage;
};

/**
 * This experiment uses the metadata object to derive it's state.
 * <p>
 * Experiment name - passkey_lighthouse_sbg
 */
export const useSbgManagementExperimentField = (): boolean => {
  const { data } = useQuery(getExperimentsByParticipantIdQuery, {
    variables: {
      experimentName: SBG_EXPERIMENT_NAME,
      context: { clientName: GraphClient.PASSKEY_RDK2 }
    },
    ssr: false,
    fetchPolicy: 'cache-first'
  });

  return data?.getExperimentByParticipantId?.metadata?.sbgManagement;
};

/**
 * This experiment uses the metadata object determine if we should show credit card details on SBG management pages.
 * <p>
 * Experiment name - passkey_lighthouse_sbg
 */
export const useSBGManagementCreditCardsExperimentField = (): boolean => {
  const { data } = useQuery(getExperimentsByParticipantIdQuery, {
    variables: {
      experimentName: SBG_EXPERIMENT_NAME
    },
    ssr: false,
    fetchPolicy: 'cache-first',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  return data?.getExperimentByParticipantId?.metadata?.sbgManagementCreditCard;
};

/**
 * This experiment uses the metadata object determine if we should show the edit credit card menus on SBG management pages.
 * <p>
 * Experiment name - passkey_lighthouse_sbg
 * Metadata name - edit_creditCard
 */
export const useSBGManagementEditCreditCardExperimentField = (): boolean => {
  const { data } = useQuery(getExperimentsByParticipantIdQuery, {
    variables: {
      experimentName: SBG_EXPERIMENT_NAME
    },
    ssr: false,
    fetchPolicy: 'cache-first',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  return data?.getExperimentByParticipantId?.metadata?.editCreditCard;
};

/**
 * Returns experiment variant for Room Categories experiment.
 * <p>
 * Experiment name - passkey_room_categories
 */
export const useRoomCategoriesExperiment = (): boolean => {
  const { data } = useQuery(getExperimentsByParticipantIdQuery, {
    variables: {
      experimentName: ROOM_CATEGORIES_EXPERIMENT_NAME
    },
    ssr: false,
    fetchPolicy: 'cache-first',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  return data?.getExperimentByParticipantId?.variantId === 1;
};

/**
 * Returns experiment variant for my Cvent integration.
 * <p>
 * Experiment name - passkey_my_cvent_integration
 */
export const useMyCventIntegrationExperiment = (myCventIntegration?): boolean => {
  let useData;
  if (myCventIntegration) {
    useData = myCventIntegration;
  } else {
    const { data } = useQuery(getExperimentsByParticipantIdQuery, {
      variables: {
        experimentName: MY_CVENT_EXPERIMENT_NAME
      },
      ssr: false,
      fetchPolicy: 'no-cache',
      context: { clientName: GraphClient.PASSKEY_RDK2 }
    });
    useData = data?.getExperimentByParticipantId;
  }

  return useData?.variantId === 1;
};

/**
 * Returns experiment variant for community integration.
 * <p>
 * Experiment name - passkey_cvent_community_integration
 */
export const useCventCommunityIntegrationExperiment = (): boolean => {
  const { data } = useQuery(getExperimentsByParticipantIdQuery, {
    variables: {
      experimentName: CVENT_COMMUNITY_EXPERIMENT_NAME
    },
    ssr: false,
    fetchPolicy: 'no-cache',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  return data?.getExperimentByParticipantId?.variantId === 1;
};

/**
 * Returns experiment variant for Help & Support integration.
 * <p>
 * Experiment name - passkey_help_and_support_integration
 */
export const useHelpAndSupportIntegrationExperiment = (): boolean => {
  const { data } = useQuery(getExperimentsByParticipantIdQuery, {
    variables: {
      experimentName: HELP_AND_SUPPORT_EXPERIMENT_NAME
    },
    ssr: false,
    fetchPolicy: 'cache-first',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  return data?.getExperimentByParticipantId?.variantId === 1;
};

/**
 * Returns experiment variant for Call Center (Redwood) Find Reservation screen.
 * <p>
 * Experiment name - passkey_callcenter_find_reservation
 */
export const useFindReservationExperiment = (findReservation?): boolean => {
  let useData;
  if (findReservation) {
    useData = findReservation;
  } else {
    const { data } = useQuery(getExperimentsByParticipantIdQuery, {
      variables: {
        experimentName: FIND_RESERVATION_EXPERIMENT_NAME
      },
      ssr: false,
      fetchPolicy: 'cache-first',
      context: { clientName: GraphClient.PASSKEY_RDK2 }
    });
    useData = data?.getExperimentByParticipantId;
  }

  return useData?.variantId === 1;
};

/**
 * Be aware that this experiment is an on/off switch.  It can
 * only have two states.  Variant ID 1 evaluates to true and
 * all other variants will evaluate to false.
 * <p>
 * Experiment name - passkey_autoblock_manual_approval
 */
export const useManualApprovalExperiment = (): boolean => {
  const { data } = useQuery(getExperimentsByParticipantIdQuery, {
    variables: {
      experimentName: MANUAL_APPROVAL_EXPERIMENT_NAME
    },
    ssr: false,
    fetchPolicy: 'cache-first',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  return data?.getExperimentByParticipantId?.variantId === 1;
};

/**
 * This experiment uses the metadata object determine if we should show the switch
 * to classic home pag banner or not.
 * <p>
 * Experiment name - passkey_switch_to_classic_banner
 */
export const useLighthouseReturnToClassicBannerExperimentField = (): boolean => {
  const { data } = useQuery(getExperimentsByParticipantIdQuery, {
    variables: {
      experimentName: SWITCH_TO_CLASSIC_BANNER
    },
    ssr: false,
    fetchPolicy: 'cache-first',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  return data?.getExperimentByParticipantId?.metadata?.showSwitchToClassicBanner;
};

/**
 * My Cvent is turned off by default for users using SSO.
 * This experiment enables My Cvent for SSO users.
 * <p>
 * Experiment name - passkey_enable_my_cvent_for_sso_users
 */
export const useMyCventForSsoUsersExperiment = (myCventForSsoUsers?): boolean => {
  let useData;
  if (myCventForSsoUsers) {
    useData = myCventForSsoUsers;
  } else {
    const { data } = useQuery(getExperimentsByParticipantIdQuery, {
      variables: {
        experimentName: MY_CVENT_SSO_USERS_EXPERIMENT_NAME
      },
      ssr: false,
      fetchPolicy: 'cache-first',
      context: { clientName: GraphClient.PASSKEY_RDK2 }
    });
    useData = data?.getExperimentByParticipantId;
  }

  return useData?.variantId === 1;
};

/**
 * An experiment that will allow us to move forward with new AB alerts frontend development
 * sprint-over-sprint without disrupting user experience in the interim
 * <p>
 * Experiment name - passkey_ab_notifications
 */
export const useABNotificationsExperiment = (abNotifications?): boolean => {
  let useData;
  if (abNotifications) {
    useData = abNotifications;
  } else {
    const { data } = useQuery(getExperimentsByParticipantIdQuery, {
      variables: {
        experimentName: AB_NOTIFICATIONS_EXPERIMENT_NAME
      },
      ssr: false,
      fetchPolicy: 'no-cache',
      context: { clientName: GraphClient.PASSKEY_RDK2 }
    });
    useData = data?.getExperimentByParticipantId;
  }

  return useData?.metadata?.homePage === 'true';
};

export const useABNotificationsSbgPageExperiment = (): boolean => {
  const { data } = useQuery(getExperimentsByParticipantIdQuery, {
    variables: {
      experimentName: AB_NOTIFICATIONS_EXPERIMENT_NAME
    },
    ssr: false,
    fetchPolicy: 'no-cache',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  return data?.getExperimentByParticipantId?.metadata?.sbgPage === 'true';
};

export const useEventOverviewExperiment = (): boolean => {
  const { data } = useQuery(getExperimentsByParticipantIdQuery, {
    variables: {
      experimentName: EVENT_OVERVIEW_EXPERIMENT
    },
    ssr: false,
    fetchPolicy: 'no-cache',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  return data?.getExperimentByParticipantId?.variantId === 1;
};
