/* eslint-disable no-restricted-imports */
import getConfig from 'next/config';
import { useMutation } from '@apollo/client';
import { ManageSessionMutation, ManageSessionMutationVariables } from '@cvent/passkey-admin-model/operations';
import { manageSessionMutation } from '@cvent/passkey-admin-model/operations/manageSession';
import { RequestBuilder, fetchAndRetryIfServerBusy } from '@cvent/nucleus-networking';

const { publicRuntimeConfig } = getConfig();

export enum SessionAction {
  LOGOUT = 'logout',
  REFRESH = 'refresh'
}

export interface ModifySessionRequest {
  resdeskEncryptedSessionId: string;
}

const useLogoutSession = () => {
  const [logoutSessionMutation] = useMutation<ManageSessionMutation, ManageSessionMutationVariables>(
    manageSessionMutation,
    {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCompleted: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {}
    }
  );
  const executeLogout = () => {
    logoutSessionMutation({ variables: { action: SessionAction.LOGOUT } }).finally(() => logOut());
  };

  return { executeLogout };
};

function getResdeskLogInPageLink(): string {
  const isDevLogin = publicRuntimeConfig.DEV_LOGIN === 'true';
  return isDevLogin ? publicRuntimeConfig.LOGIN_URL : `${publicRuntimeConfig.LOGIN_URL}/Login.action?logout=true&mem=1`;
}

/**
 * Logout function is redirecting the user to Resdesk logout page,
 * which is expiring the Resdesk token.
 */
const logOut = () => {
  const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')();
  if (isBrowser) {
    window.location.href = getResdeskLogInPageLink();

    sessionStorage.clear();
    localStorage.clear();
  }
};

/**
 * This code pertains to the use of the health check endpoint in Resdesk for keep alive requests from Lighthouse,
 * which is necessary to maintain the ALB Stick Session Cookie.
 * The function is triggered based on the KEEP_ALIVE_INTERVAL set in the session handler.
 * We always return void regardless of the status of the keep alive call.
 * This is done to prevent the keep alive calls from failing lighthouse.
 */
const makeRefreshCsrfRequest = async () => {
  if (publicRuntimeConfig.DEV_LOGIN !== 'true') {
    const resdeskKeepAliveRequest = new RequestBuilder({
      url: `${publicRuntimeConfig.KEEP_ALIVE_ENDPOINT}/ok`,
      credentials: 'include'
    })
      .get()
      .build();
    await fetchAndRetryIfServerBusy(resdeskKeepAliveRequest);
  }
};

export { makeRefreshCsrfRequest, logOut, useLogoutSession };
