"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNotificationList = exports.getInventoryRequestFinalizeHotelNotifications = exports.getInventoryRequestReceivedOrganizerNotifications = exports.getInventoryRequestReceivedPendingHotelNotifications = exports.getPostEventReconciliationReportsNotifications = exports.getSubBlockRequestPendingApprovalNotifications = exports.getSubBlockRequestAutoblockNotifications = exports.getSubBlockRequestNotifications = exports.getAutoblockRequestNotifications = exports.getGroupMasterLinkNotifications = exports.getGroupMasterLinkAutoCreateExcludedNotifications = exports.getGroupMasterLinkAutoCreateNotifications = exports.getGroupLinkSetupNotifications = exports.getUnprocessedReservationNotifications = exports.getRoomListNotifications = exports.getWaitListNotifications = exports.getPasskeyNotificationCounts = void 0;
var client_1 = require("@apollo/client");
exports.getPasskeyNotificationCounts = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getPasskeyNotificationCounts($alertType: String) {\n    passkeyNotificationCounts(alertType: $alertType) {\n      userId\n      alertsEntities {\n        alertType\n        count\n      }\n    }\n  }\n"], ["\n  query getPasskeyNotificationCounts($alertType: String) {\n    passkeyNotificationCounts(alertType: $alertType) {\n      userId\n      alertsEntities {\n        alertType\n        count\n      }\n    }\n  }\n"])));
exports.getWaitListNotifications = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getWaitListNotifications($eventId: ID, $index: String, $limit: Int) {\n    waitListNotifications(eventId: $eventId, index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getWaitListNotifications($eventId: ID, $index: String, $limit: Int) {\n    waitListNotifications(eventId: $eventId, index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getRoomListNotifications = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query getRoomListNotifications($eventId: ID, $index: String, $limit: Int) {\n    roomListNotifications(eventId: $eventId, index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getRoomListNotifications($eventId: ID, $index: String, $limit: Int) {\n    roomListNotifications(eventId: $eventId, index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getUnprocessedReservationNotifications = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query getUnprocessedReservationNotifications($eventId: ID, $index: String, $limit: Int) {\n    unprocessedReservationNotifications(eventId: $eventId, index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getUnprocessedReservationNotifications($eventId: ID, $index: String, $limit: Int) {\n    unprocessedReservationNotifications(eventId: $eventId, index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getGroupLinkSetupNotifications = (0, client_1.gql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  query getGroupLinkSetupNotifications($eventId: ID, $index: String, $limit: Int) {\n    groupLinkSetupNotifications(eventId: $eventId, index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n          associatedHotels {\n            hotelId\n            hotelName\n          }\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getGroupLinkSetupNotifications($eventId: ID, $index: String, $limit: Int) {\n    groupLinkSetupNotifications(eventId: $eventId, index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n          associatedHotels {\n            hotelId\n            hotelName\n          }\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getGroupMasterLinkAutoCreateNotifications = (0, client_1.gql)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  query getGroupMasterLinkAutoCreateNotifications($index: String, $limit: Int) {\n    groupMasterLinkAutoCreateNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getGroupMasterLinkAutoCreateNotifications($index: String, $limit: Int) {\n    groupMasterLinkAutoCreateNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getGroupMasterLinkAutoCreateExcludedNotifications = (0, client_1.gql)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  query getGroupMasterLinkAutoCreateExcludedNotifications($index: String, $limit: Int) {\n    groupMasterLinkAutoCreateExcludedNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          hotelId\n          hotelName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getGroupMasterLinkAutoCreateExcludedNotifications($index: String, $limit: Int) {\n    groupMasterLinkAutoCreateExcludedNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          hotelId\n          hotelName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getGroupMasterLinkNotifications = (0, client_1.gql)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  query getGroupMasterLinkNotifications($index: String, $limit: Int) {\n    groupMasterLinkNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          hotelId\n          hotelName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getGroupMasterLinkNotifications($index: String, $limit: Int) {\n    groupMasterLinkNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          hotelId\n          hotelName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getAutoblockRequestNotifications = (0, client_1.gql)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  query getAutoblockRequestNotifications($index: String, $limit: Int) {\n    autoblockRequestNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          abDailyCount\n          abPendingCount\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getAutoblockRequestNotifications($index: String, $limit: Int) {\n    autoblockRequestNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          abDailyCount\n          abPendingCount\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getSubBlockRequestNotifications = (0, client_1.gql)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  query getSubBlockRequestNotifications($index: String, $limit: Int) {\n    subBlockRequestNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getSubBlockRequestNotifications($index: String, $limit: Int) {\n    subBlockRequestNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getSubBlockRequestAutoblockNotifications = (0, client_1.gql)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  query getSubBlockRequestAutoblockNotifications($index: String, $limit: Int) {\n    subBlockRequestAutoblockNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getSubBlockRequestAutoblockNotifications($index: String, $limit: Int) {\n    subBlockRequestAutoblockNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getSubBlockRequestPendingApprovalNotifications = (0, client_1.gql)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  query getSubBlockRequestPendingApprovalNotifications($index: String, $limit: Int) {\n    subBlockRequestPendingApprovalNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getSubBlockRequestPendingApprovalNotifications($index: String, $limit: Int) {\n    subBlockRequestPendingApprovalNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getPostEventReconciliationReportsNotifications = (0, client_1.gql)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  query getPostEventReconciliationReportsNotifications($index: String, $limit: Int) {\n    postEventReconciliationReportsNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getPostEventReconciliationReportsNotifications($index: String, $limit: Int) {\n    postEventReconciliationReportsNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getInventoryRequestReceivedPendingHotelNotifications = (0, client_1.gql)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  query getInventoryRequestReceivedPendingHotelNotifications($index: String, $limit: Int) {\n    inventoryRequestReceivedPendingHotelNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getInventoryRequestReceivedPendingHotelNotifications($index: String, $limit: Int) {\n    inventoryRequestReceivedPendingHotelNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getInventoryRequestReceivedOrganizerNotifications = (0, client_1.gql)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  query getInventoryRequestReceivedOrganizerNotifications($index: String, $limit: Int) {\n    inventoryRequestReceivedOrganizerNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getInventoryRequestReceivedOrganizerNotifications($index: String, $limit: Int) {\n    inventoryRequestReceivedOrganizerNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getInventoryRequestFinalizeHotelNotifications = (0, client_1.gql)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  query getInventoryRequestFinalizeHotelNotifications($index: String, $limit: Int) {\n    inventoryRequestFinalizeHotelNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"], ["\n  query getInventoryRequestFinalizeHotelNotifications($index: String, $limit: Int) {\n    inventoryRequestFinalizeHotelNotifications(index: $index, limit: $limit) {\n      userId\n      participantId\n      userNotifications {\n        notificationType\n        notificationEntities {\n          count\n          eventId\n          eventName\n        }\n      }\n      pageInfo {\n        index\n        totalCount\n      }\n    }\n  }\n"])));
exports.getNotificationList = (0, client_1.gql)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  query getNotificationList {\n    notificationList {\n      notificationTypes\n    }\n  }\n"], ["\n  query getNotificationList {\n    notificationList {\n      notificationTypes\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
