"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReglinkAccess = exports.PermissionType = exports.NotificationType = exports.DistanceUnit = exports.AccessLevel = void 0;
var AccessLevel;
(function (AccessLevel) {
    AccessLevel["Add"] = "ADD";
    AccessLevel["Delete"] = "DELETE";
    AccessLevel["Modify"] = "MODIFY";
    AccessLevel["NoAccess"] = "NO_ACCESS";
    AccessLevel["Read"] = "READ";
    AccessLevel["Upload"] = "UPLOAD";
    AccessLevel["View"] = "VIEW";
})(AccessLevel = exports.AccessLevel || (exports.AccessLevel = {}));
var DistanceUnit;
(function (DistanceUnit) {
    DistanceUnit["Block"] = "BLOCK";
    DistanceUnit["Kilometer"] = "KILOMETER";
    DistanceUnit["Meter"] = "METER";
    DistanceUnit["Mile"] = "MILE";
    DistanceUnit["None"] = "NONE";
    DistanceUnit["Yard"] = "YARD";
})(DistanceUnit = exports.DistanceUnit || (exports.DistanceUnit = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["AutoblockRequests"] = "AUTOBLOCK_REQUESTS";
    NotificationType["GroupLinkSetup"] = "GROUP_LINK_SETUP";
    NotificationType["GroupMasterLink"] = "GROUP_MASTER_LINK";
    NotificationType["GroupMasterLinkAutoCreate"] = "GROUP_MASTER_LINK_AUTO_CREATE";
    NotificationType["GroupMasterLinkAutoCreateExcluded"] = "GROUP_MASTER_LINK_AUTO_CREATE_EXCLUDED";
    NotificationType["InvReqHotel"] = "INV_REQ_HOTEL";
    NotificationType["InvReqHotelFinal"] = "INV_REQ_HOTEL_FINAL";
    NotificationType["InvReqOrg"] = "INV_REQ_ORG";
    NotificationType["IrHotel"] = "IR_HOTEL";
    NotificationType["IrHotelHomeAlert"] = "IR_HOTEL_HOME_ALERT";
    NotificationType["IrHotelHomeFinalAlert"] = "IR_HOTEL_HOME_FINAL_ALERT";
    NotificationType["IrOrg"] = "IR_ORG";
    NotificationType["IrOrgHomeAlert"] = "IR_ORG_HOME_ALERT";
    NotificationType["PostEventReports"] = "POST_EVENT_REPORTS";
    NotificationType["SubblockRequests"] = "SUBBLOCK_REQUESTS";
    NotificationType["SubblockRequestsAutoblock"] = "SUBBLOCK_REQUESTS_AUTOBLOCK";
    NotificationType["SubblockRequestsPendingApproval"] = "SUBBLOCK_REQUESTS_PENDING_APPROVAL";
    NotificationType["UnprocessedReservations"] = "UNPROCESSED_RESERVATIONS";
    NotificationType["UnprocessedRoomLists"] = "UNPROCESSED_ROOM_LISTS";
    NotificationType["WaitList"] = "WAIT_LIST";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
var PermissionType;
(function (PermissionType) {
    PermissionType["ModulePrivilege"] = "modulePrivilege";
    PermissionType["Permission"] = "permission";
})(PermissionType = exports.PermissionType || (exports.PermissionType = {}));
var ReglinkAccess;
(function (ReglinkAccess) {
    ReglinkAccess["NoAccess"] = "NO_ACCESS";
    ReglinkAccess["ReglinkViewReservations"] = "REGLINK_VIEW_RESERVATIONS";
})(ReglinkAccess = exports.ReglinkAccess || (exports.ReglinkAccess = {}));
