"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMyCventWidgetMagicLinkQuery = exports.getCventAccountListByPkUserIdQuery = exports.getCventAccountsQuery = void 0;
var client_1 = require("@apollo/client");
exports.getCventAccountsQuery = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getCventAccountsQuery($platformUserId: String!) {\n    cventAccountList(platformUserId: $platformUserId) {\n      platformUserId\n      entity\n      productId\n      lastAccessed\n      accountId\n      nickname\n      accountStatus\n      isActive\n      username\n      internalAccountId\n    }\n  }\n"], ["\n  query getCventAccountsQuery($platformUserId: String!) {\n    cventAccountList(platformUserId: $platformUserId) {\n      platformUserId\n      entity\n      productId\n      lastAccessed\n      accountId\n      nickname\n      accountStatus\n      isActive\n      username\n      internalAccountId\n    }\n  }\n"])));
exports.getCventAccountListByPkUserIdQuery = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getCventAccountListByPkUserIdQuery {\n    cventAccountListByPkUserId {\n      platformUserId\n      entity\n      productId\n      lastAccessed\n      accountId\n      nickname\n      accountStatus\n      isActive\n      username\n      internalAccountId\n    }\n  }\n"], ["\n  query getCventAccountListByPkUserIdQuery {\n    cventAccountListByPkUserId {\n      platformUserId\n      entity\n      productId\n      lastAccessed\n      accountId\n      nickname\n      accountStatus\n      isActive\n      username\n      internalAccountId\n    }\n  }\n"])));
exports.getMyCventWidgetMagicLinkQuery = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query getMyCventWidgetMagicLinkQuery($input: MyCventWidgetLoginInput) {\n    myCventWidgetMagicLink(input: $input) {\n      magicLink\n    }\n  }\n"], ["\n  query getMyCventWidgetMagicLinkQuery($input: MyCventWidgetLoginInput) {\n    myCventWidgetMagicLink(input: $input) {\n      magicLink\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
