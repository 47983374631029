import getConfig from 'next/config';
import { RumInitConfiguration } from '@datadog/browser-rum';

const { publicRuntimeConfig } = getConfig();

export const getDataDogOptions = (): RumInitConfiguration => ({
  applicationId: publicRuntimeConfig.DD_APP_ID,
  clientToken: publicRuntimeConfig.DD_CLIENT_TOKEN,
  service: publicRuntimeConfig.DD_SERVICE,
  env: publicRuntimeConfig.DD_ENV,
  version: publicRuntimeConfig.CLIENT_VERSION,
  allowedTracingUrls: [window.location.origin],
  trackResources: true,
  trackUserInteractions: true,
  trackLongTasks: true,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 5,
  trackSessionAcrossSubdomains: true,
  useSecureSessionCookie: true,
  useCrossSiteSessionCookie: true,
  traceSampleRate: 25,
  trackViewsManually: false,
  excludedActivityUrls: [
    // Heartbeat +
    /cvent.pubnubapi.com/,
    /stream-io-api.com/,
    // Analytics
    /api-js.mixpanel.com/,
    /batch_facts/,
    /analytics/,
    // Others
    /salesforceliveagent.com/
  ]
});
