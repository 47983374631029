import React from 'react';

import 'normalize.css'; // css reset
import '@cvent/carina/fonts/fonts.web.css';
import type { ReactNode } from 'react';

import { ThemeProvider } from '@components/ThemeProvider';
import { ThemeProvider as ThemeProviderV0 } from '@cvent/carina-theme-provider'; // We're still using come CarinaV0 components that have not yet been migrated to CarinaV1, as of 1.35.0, so we need the old ThemeProvider for those components
import { Breakpoints } from '@cvent/carina/components/Breakpoints';
import { GlobalStyles } from '@components/GlobalStyles';
import { LocalizationProvider } from 'nucleus-text';
import { useQuery } from '@apollo/client';
import { GetLocaleDocument } from '@cvent/passkey-admin-model/operations';
import { getDefaultTheme } from '@cvent/carina/utils/tokens';
import locales from '../../locales';

export default function AppProviders({ children }: { children: ReactNode }): JSX.Element {
  const { data } = useQuery(GetLocaleDocument);
  const { locale } = data;

  return (
    <ThemeProvider>
      <LocalizationProvider locale={locale} clientSideLocales={locales} serverSideLocales={locales}>
        <ThemeProviderV0 theme={getDefaultTheme()}>
          <GlobalStyles />
          <Breakpoints ssrSize="m" key={typeof window === 'undefined' ? 'ssr' : 'csr'}>
            {children}
          </Breakpoints>
        </ThemeProviderV0>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
