/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line import/order

// TODO: move this css boilerplate into a next.js plugin
import 'normalize.css'; // css reset
import '@cvent/carina/fonts/fonts.web.css';
import '@styles/global.css';
import '../config/initBrowserLogging';

import React, { useMemo } from 'react';
import { ApolloProvider, DocumentNode, from, HttpLink, InMemoryCache, makeVar, split, useQuery } from '@apollo/client';
import { ApolloClientFactory, createAuthLink, useApollo } from '@cvent/apollo-client';
import AppProviders from '@components/AppProviders';
import { onError } from '@apollo/client/link/error';
import { getItem, Link } from '@cvent/nextjs';
import { NavigationProvider } from '@cvent/carina/components/Navigation';
import { useRouter } from 'next/router';
import { GET_APP_NAVIGATION_QUERY } from '@cvent/planner-navigation/client/queries';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import getConfig from 'next/config';
import ErrorBoundary from '@components/ErrorBoundary';
import { getRdk2GraphHttpLink, GraphClient } from '@utils/apolloClientUtils';
import { BasePage } from '@components/BasePage';
import { defaultLocale } from '../../locales';
import { initializeRUM } from '../config/initRum';

const { publicRuntimeConfig } = getConfig();
const LOG = LoggerFactory.create('App');

// example of app wide global state, move to another file and import here as this grows!
// to learn more options for local state management go here:
//    - https://www.apollographql.com/blog/local-state-management-with-reactive-variables/
export const darkModeVar = makeVar<boolean>(false);
export const clientVersion = makeVar<string>(String(process.env.CLIENT_VERSION) || 'unknown');
export const localeVar = makeVar<string>(getItem('locale') || defaultLocale);
export const hasInitDatadog = makeVar<boolean>(false);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      LOG.debug(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }
  if (networkError) {
    LOG.debug(`[Network error]: ${networkError}`);
  }
});

const httpLink = new HttpLink({
  uri: '/api/graphql'
});

const createApolloLinks = () => {
  const rdk2GraphLink = getRdk2GraphHttpLink(publicRuntimeConfig.RDK2_GRAPH_URL);

  const rdk2Link = split(operation => operation.getContext().clientName === GraphClient.PASSKEY_RDK2, rdk2GraphLink);

  if (publicRuntimeConfig.DEV_LOGIN === 'true') {
    const loginUrl = publicRuntimeConfig.LOGIN_URL?.replace('http://', ''); // strip out the `http://` from the login URL
    return [createAuthLink(loginUrl), rdk2Link, errorLink, httpLink];
  }
  return [createAuthLink(), rdk2Link, errorLink, httpLink];
};

export default function App({ Component, pageProps }): JSX.Element {
  initializeRUM();

  const client = useApollo(
    pageProps,
    new ApolloClientFactory({
      initialState: {},
      loginUrl: publicRuntimeConfig.LOGIN_URL || 'login',
      errorUrl: '/_error',
      graphBasePath: process.env.NEXT_PUBLIC_BASE_PATH,
      graphUri: `${process.env.BASE_PATH}/api/graphql`
    }),
    {
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              clientVersion: {
                read(): string {
                  return clientVersion();
                }
              },
              darkMode: {
                read(): boolean {
                  return darkModeVar();
                }
              },
              locale: {
                read(): string {
                  return localeVar();
                }
              }
            }
          }
        }
      }),
      // set any global apollo overrides here for browser side apollo
      link: from(createApolloLinks()),
      connectToDevTools:
        publicRuntimeConfig.DD_ENV.toLowerCase().includes('dev') || publicRuntimeConfig.DD_ENV.toLowerCase() === 'alpha'
    }
  );

  const router = useRouter();

  const navMetadata = pageProps?.navMetadata || Component.navMetadata;
  const { loading, data } = useQuery(GET_APP_NAVIGATION_QUERY as unknown as DocumentNode, {
    client,
    variables: {
      navMetadata: pageProps?.navMetadata || Component.navMetadata
    },
    skip: !navMetadata
  });

  const component = useMemo(() => {
    if (loading) {
      // TODO: empty for now, discuss options with Carina team and UX.
      // implement getStaticProps or getServerSideProps on a page to avoid
      return <div />;
    }

    const handlers = {
      setSearchTerm: (term): void => {
        LOG.info('SEARCH TERM:', term);
      },
      submitFilters: (content): void => {
        LOG.info('FILTERS CONTENT', content);
      },
      onSearch: (term?: string): void => {
        LOG.info('onSearch', term);
        router.push(`/search?term=${encodeURIComponent(term)}`);
      }
    };

    const nav = {
      ...data?.navigation,
      ...handlers,
      Link
    };

    return (
      <ApolloProvider client={client}>
        <AppProviders>
          <ErrorBoundary>
            {navMetadata ? (
              <NavigationProvider {...nav}>
                <BasePage>
                  <Component {...pageProps} />
                </BasePage>
              </NavigationProvider>
            ) : (
              <Component {...pageProps} />
            )}
          </ErrorBoundary>
        </AppProviders>
      </ApolloProvider>
    );
  }, [loading, data?.navigation, client, navMetadata, Component, pageProps, router]);

  return component;
}
