import cookies from 'js-cookie';
import { ApolloLink, createHttpLink } from '@apollo/client';
import { getAuthCookieName } from '@cvent/nextjs/auth/constants';

export enum GraphClient {
  PASSKEY_RDK2 = 'passkey-rdk2',
  PASSKEY_ADMIN = 'passkey-admin'
}

export const getRdk2GraphHttpLink = (rdk2GraphUrl: string): ApolloLink => {
  const authToken = cookies.get(getAuthCookieName());

  return createHttpLink({
    uri: rdk2GraphUrl,
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : ''
    }
  });
};
