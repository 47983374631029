"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPlatformUserAssoc = exports.getPlatformUserId = void 0;
var client_1 = require("@apollo/client");
exports.getPlatformUserId = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getPlatformUserId {\n    platformUserId {\n      platformUserId\n    }\n  }\n"], ["\n  query getPlatformUserId {\n    platformUserId {\n      platformUserId\n    }\n  }\n"])));
exports.getPlatformUserAssoc = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getPlatformUserAssoc {\n    platformUserAssoc {\n      userId\n      platformUserId\n      active\n      createdByUserId\n      createdTimestamp\n      pkUserId\n      pkTimestamp\n      ssoEnabled\n    }\n  }\n"], ["\n  query getPlatformUserAssoc {\n    platformUserAssoc {\n      userId\n      platformUserId\n      active\n      createdByUserId\n      createdTimestamp\n      pkUserId\n      pkTimestamp\n      ssoEnabled\n    }\n  }\n"])));
var templateObject_1, templateObject_2;
