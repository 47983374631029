import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { TopNavWrapper as GlobalTopNav } from '@cvent/passkey-rdk2-lib/dist/src/components/navigation/top/global/TopNavWrapper';

import { askAboutUxContentQuery, getPermissionsQuery } from '@cvent/passkey-admin-model/operations/rdk2/permissions';
import { getTopNavExperimentsQuery } from '@cvent/passkey-admin-model/operations/rdk2/experiment';
import getConfig from 'next/config';
import { getPlatformUserAssoc } from '@cvent/passkey-admin-model/operations/rdk2/platformUser';
import {
  isExperimentEnabled,
  useCventCommunityIntegrationExperiment,
  useHelpAndSupportIntegrationExperiment
} from '@hooks/rdk2/features';
import { useRouter } from 'next/router';
import { displayMyCventMenuItems } from '@utils/rdk2/utils';
import { NavigationConfig } from '@cvent/passkey-rdk2-lib/dist/src/types';
import { getMagicLinkQuery } from '@cvent/passkey-admin-model/src/operations/rdk2/magicLink';
import {
  getCventAccountListByPkUserIdQuery,
  getMyCventWidgetMagicLinkQuery
} from '@cvent/passkey-admin-model/operations/rdk2/cventAccount';
import { getGroupMasterLinkHotels } from '@cvent/passkey-admin-model/operations/rdk2/groupMasterLink';
import {
  getAutoblockRequestNotifications,
  getGroupLinkSetupNotifications,
  getGroupMasterLinkAutoCreateExcludedNotifications,
  getGroupMasterLinkAutoCreateNotifications,
  getGroupMasterLinkNotifications,
  getInventoryRequestFinalizeHotelNotifications,
  getInventoryRequestReceivedOrganizerNotifications,
  getInventoryRequestReceivedPendingHotelNotifications,
  getNotificationList,
  getPasskeyNotificationCounts,
  getPostEventReconciliationReportsNotifications,
  getRoomListNotifications,
  getSubBlockRequestNotifications,
  getUnprocessedReservationNotifications,
  getWaitListNotifications
} from '@cvent/passkey-admin-model/operations/rdk2/notifications';
import { GraphClient } from '@utils/apolloClientUtils';
import { fetchMetadata } from '@cvent/passkey-admin-model/src/operations/rdk2/auth';
import { NotificationType } from '@cvent/passkey-admin-model/types';
import { encodeNumber } from '@utils/encoder';
import { logOut } from '@components/userSession/utils';

const { publicRuntimeConfig } = getConfig();

const temporaryLimit = 250; // we will switch to using the NOTIFICATION_LIMIT value once we refactor this component

const COMMUNITY_DIRECT_LINK = 'https://community.cvent.com/home';
const SUPPORT_DIRECT_LINK = 'https://support.cvent.com/s/knowledgebase';
const COMMUNITY_PRODUCT_ID = 'communityProductId';
const SUPPORT_PRODUCT_ID = 'helpAndSupportProductId';

export function getDirectLinkOnError(errorResponse: string) {
  if (errorResponse.includes(SUPPORT_PRODUCT_ID)) {
    return SUPPORT_DIRECT_LINK;
  }
  if (errorResponse.includes(COMMUNITY_PRODUCT_ID)) {
    return COMMUNITY_DIRECT_LINK;
  }
  return COMMUNITY_DIRECT_LINK;
}

const notificationQueries = {
  [NotificationType.WaitList]: getWaitListNotifications,
  [NotificationType.UnprocessedReservations]: getUnprocessedReservationNotifications,
  [NotificationType.SubblockRequests]: getSubBlockRequestNotifications,
  [NotificationType.UnprocessedRoomLists]: getRoomListNotifications,
  [NotificationType.PostEventReports]: getPostEventReconciliationReportsNotifications,
  [NotificationType.GroupLinkSetup]: getGroupLinkSetupNotifications,
  [NotificationType.GroupMasterLink]: getGroupMasterLinkNotifications,
  [NotificationType.GroupMasterLinkAutoCreate]: getGroupMasterLinkAutoCreateNotifications,
  [NotificationType.GroupMasterLinkAutoCreateExcluded]: getGroupMasterLinkAutoCreateExcludedNotifications,
  [NotificationType.IrOrgHomeAlert]: getInventoryRequestReceivedOrganizerNotifications,
  [NotificationType.InvReqOrg]: getInventoryRequestReceivedOrganizerNotifications,
  [NotificationType.IrOrg]: getInventoryRequestReceivedOrganizerNotifications,
  [NotificationType.IrHotel]: getInventoryRequestReceivedPendingHotelNotifications,
  [NotificationType.IrHotelHomeAlert]: getInventoryRequestReceivedPendingHotelNotifications,
  [NotificationType.InvReqHotelFinal]: getInventoryRequestFinalizeHotelNotifications,
  [NotificationType.IrHotelHomeFinalAlert]: getInventoryRequestFinalizeHotelNotifications,
  [NotificationType.InvReqHotel]: getInventoryRequestFinalizeHotelNotifications,
  [NotificationType.AutoblockRequests]: getAutoblockRequestNotifications
};

const useNotificationHook = (type: NotificationType) => {
  const query = notificationQueries[type];
  const limit =
    type === NotificationType.GroupMasterLink ||
    type === NotificationType.GroupMasterLinkAutoCreate ||
    type === NotificationType.GroupMasterLinkAutoCreateExcluded
      ? temporaryLimit
      : 10;

  const { loading, error, data, refetch } = useQuery(query, {
    variables: {
      index: encodeNumber(1),
      limit
    },
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  const notificationsTotalCount = data?.[Object.keys(data)[0]]?.pageInfo.totalCount;
  const notificationsNextIndex = data?.[Object.keys(data)[0]]?.pageInfo.index;
  const userNotifications = data?.[Object.keys(data)[0]]?.userNotifications;

  return {
    loading,
    error,
    notificationsTotalCount,
    notificationsNextIndex,
    userNotifications,
    refetch: async () => {
      refetch({
        index: notificationsNextIndex,
        limit
      });
    }
  };
};

export function TopNavigation({ currentPathname }: { currentPathname: string }): JSX.Element {
  const { loading: metadataLoading, data: metadataInfo } = useQuery(fetchMetadata, {
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });
  const { loading: contentLoading, data: topNavInfo } = useQuery(askAboutUxContentQuery, {
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });
  const { loading: permissionsLoading, data: permissions } = useQuery(getPermissionsQuery, {
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });
  const { loading: experimentsLoading, data: experiments } = useQuery(getTopNavExperimentsQuery, {
    ssr: false,
    fetchPolicy: 'no-cache',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  const router = useRouter();

  const isNavigationEnabled = !experimentsLoading && isExperimentEnabled(experiments.lighthouse);
  const isFindReservationEnabled = !experimentsLoading && isExperimentEnabled(experiments.findReservation);
  const isMyCventEnabled = !experimentsLoading && isExperimentEnabled(experiments.myCventIntegration);
  const isMyCventEnabledForSsoUsers = !experimentsLoading && isExperimentEnabled(experiments.myCventForSsoUsers);
  const areAbNotificationsEnabled = !experimentsLoading && isExperimentEnabled(experiments.abNotifications);

  const { data: platformUserAssocData } = useQuery(getPlatformUserAssoc, {
    skip: !isMyCventEnabled,
    ssr: false,
    fetchPolicy: 'cache-first',
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  const platformUserAssoc = platformUserAssocData?.platformUserAssoc;
  const platformUserId = platformUserAssoc?.platformUserId;
  const isSsoEnabled = platformUserAssoc?.ssoEnabled;

  const isEnabled: boolean = displayMyCventMenuItems(
    platformUserAssoc,
    isSsoEnabled,
    isMyCventEnabled,
    isMyCventEnabledForSsoUsers
  );

  const [getMyCventWidgetMagicLink] = useLazyQuery(getMyCventWidgetMagicLinkQuery, {
    context: { clientName: GraphClient.PASSKEY_RDK2 },
    onCompleted: magicLinkData => {
      logOut();
      window.open(magicLinkData?.myCventWidgetMagicLink?.magicLink, '_self');
    },
    fetchPolicy: 'no-cache'
  });

  const { data: cventAccountListData } = useQuery(getCventAccountListByPkUserIdQuery, {
    skip: !isEnabled,
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  const cventAccounts = cventAccountListData?.cventAccountListByPkUserId;

  // magicLink
  const [getMagicLinkForLink] = useLazyQuery(getMagicLinkQuery, {
    context: { clientName: GraphClient.PASSKEY_RDK2 },
    onCompleted: magicLinkData => {
      logOut();
      window.open(magicLinkData?.magicLink?.link, '_self');
    },
    fetchPolicy: 'no-cache'
  });

  // gmlQueue
  const {
    loading: gmlQueueEnabledHotelsLoading,
    error: gmlQueueEnabledHotelsError,
    data: gmlQueueEnabledHotels
  } = useQuery(getGroupMasterLinkHotels, {
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  const {
    loading: userMetadataLoading,
    error: userMetadataError,
    data: userMetadata
  } = useQuery(fetchMetadata, { context: { clientName: GraphClient.PASSKEY_RDK2 } });

  const navigationConfig: NavigationConfig = {
    resdeskLoginUrl: publicRuntimeConfig.LOGIN_URL,
    roomListUrl: `https://${publicRuntimeConfig.RLM_URL}`
  };

  // helpCenter
  const isCommunityEnabled = useCventCommunityIntegrationExperiment();
  const isHelpAndSupportAuthEnabled = useHelpAndSupportIntegrationExperiment();

  const [getMagicLinkHelpCenter] = useLazyQuery(getMagicLinkQuery, {
    context: { clientName: GraphClient.PASSKEY_RDK2 },
    onCompleted: magicLinkData => {
      const link = magicLinkData?.magicLink?.link;
      if (link.includes('error')) {
        window.open(getDirectLinkOnError(link), '_blank');
      } else {
        window.open(link, '_blank');
      }
    },
    fetchPolicy: 'no-cache'
  });

  // Notifications
  const {
    loading: notificationsLoading,
    error: notificationsError,
    data: notificationsData
  } = useQuery(getPasskeyNotificationCounts, {
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  const { loading, error, data } = useQuery(getNotificationList, {
    context: { clientName: GraphClient.PASSKEY_RDK2 }
  });

  if (userMetadataLoading || userMetadataError) {
    return null; // TODO implement loading and error modal state
  }

  // No spinner or error card is needed due to initial list load.
  if (loading || error) {
    return null;
  }

  const notificationList: Array<NotificationType> = data?.notificationList?.notificationTypes;

  return (
    <GlobalTopNav
      currentPathname={currentPathname}
      topNavInfo={{ loading: contentLoading, data: topNavInfo }}
      cventAccountsConfig={{
        isEnabled,
        platformUserId,
        cventAccounts,
        getMagicLink: getMyCventWidgetMagicLink,
        participantId: userMetadata.fetchMetadata?.authorization?.metadata?.participantId
      }}
      isNavigationEnabled={isNavigationEnabled}
      areAbNotificationsEnabled={areAbNotificationsEnabled}
      isFindReservationEnabled={isFindReservationEnabled}
      gmlQueue={{
        gmlQueueEnabledHotelsError,
        gmlQueueEnabledHotelsLoading,
        gmlQueueEnabledHotelsData: gmlQueueEnabledHotels,
        userTypeId: userMetadata.fetchMetadata?.authorization?.metadata?.userTypeId,
        navigationConfig,
        loadingHook: userMetadataLoading
      }}
      helpCenter={{
        helpAndSupportLink: publicRuntimeConfig.HELP_AND_SUPPORT_LINK,
        isHelpAndSupportAuthEnabled,
        isCommunityEnabled,
        getMagicLink: getMagicLinkHelpCenter
      }}
      permissions={{ loading: permissionsLoading, data: permissions }}
      magicLink={{ getMagicLink: getMagicLinkForLink }}
      metaData={{ loading: metadataLoading, data: metadataInfo }}
      env={publicRuntimeConfig.ENVIRONMENT}
      router={router}
      navConfig={{
        resdeskLoginUrl: publicRuntimeConfig.RESDESK_URL,
        roomListUrl: publicRuntimeConfig.RLM_URL
      }}
      notifications={{
        notificationList,
        notificationCountsData: notificationsData,
        data: notificationsData,
        loading: notificationsLoading,
        error: notificationsError,
        getNotificationHook: useNotificationHook,
        areAbNotificationsEnabled
      }}
    />
  );
}
