/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
import React, { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { SessionHandler } from '@cvent/carina-session-handler';
import { logOut, makeRefreshCsrfRequest, useLogoutSession, SessionAction } from '@components/userSession/utils';
import { useTranslate } from 'nucleus-text';
import { useMutation } from '@apollo/client';
import { StatusCodes } from 'http-status-codes';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import getConfig from 'next/config';
import { ManageSessionMutation, ManageSessionMutationVariables } from '@cvent/passkey-admin-model/operations';
import { manageSessionMutation } from '@cvent/passkey-admin-model/operations/manageSession';

const KEEP_ALIVE_INTERVAL = 60;
const EXTEND_SESSION_BY = 15 * KEEP_ALIVE_INTERVAL;

const LOG = LoggerFactory.create('SessionHandler');

const { publicRuntimeConfig } = getConfig();

const ALLOWED_DOMAINS: string[] = publicRuntimeConfig.ALLOWED_ORIGINS_FOR_USER_ACTIVITY.split(' ');

interface HandlerProps {
  /** The children to be protected by the session provider */
  children: ReactNode;
  /** The number of seconds that should elapse between session-handler requests */
  keepAliveInterval?: number;
  /** A function that handles logging out a user. */
  makeLogOut?: (...args: any[]) => Error;
  /** An async function that performs a session-handler request and returns the number of seconds by which to extend a user's session. */
  makeSessionHandlerRequest?: (...args: any[]) => Error;
  /** An async function that refreshes the users CSRF token. */
  makeRefreshCsrfRequest?: (...args: any[]) => Error;
  /** A function to redirect the user to a login page. */
  redirectToLogin?: (...args: any[]) => Error;
  /** A localized string to the effect of "Are you still there?". */
  stillThereText?: string;
  /** A localized string to the effect of "You are about to be logged out due to inactivity". */
  noticeText?: string;
  /** A localized string to the effect of "Log out". */
  logOutText?: string;
  /** A localized string to the effect of "Keep working". */
  keepWorkingText?: string;
  /** A localized string to the effect of "Failed to refresh your session; try again". */
  refreshErrorText?: string;
}

/**
 * The component wraps Carina Session Handler component and provides API over the props to set up configurations.
 * <p/>
 * Universal Session Handler component's main goal is to abstract rich Carina API that Passkey Admin doesn't need to provide
 * common functions on each usage leve for satisfy Carina component rather allows access to configurable properties
 * in case of provided properties.
 *
 * @param props contains HandlerProps
 */
export function UniversalSessionHandler(props: HandlerProps): ReactElement {
  const { translate } = useTranslate();
  const { executeLogout } = useLogoutSession();

  const [extendSessionMutation] = useMutation<ManageSessionMutation, ManageSessionMutationVariables>(
    manageSessionMutation,
    {
      onCompleted: data => {
        LOG.debug(`Extending user session response: ${JSON.stringify(data)}`);
        if (data?.manageSession?.responseStatus === StatusCodes.OK) {
          LOG.debug(`User session extended by: ${EXTEND_SESSION_BY}`);
          return;
        }
        LOG.debug(
          `The session extension has failed with ${data?.manageSession?.responseStatus} status code for ${EXTEND_SESSION_BY} session.`
        );
        if (publicRuntimeConfig.DEV_LOGIN !== 'true') {
          executeLogout();
        }
      },
      onError: async error => {
        LOG.error(`Error Extending user session: ${JSON.stringify(error)}`);
        executeLogout();
      }
    }
  );

  /**
   * Returns true if the specified origin string ends with a domain provided in our domain whitelist.
   * @param origin
   */
  const endsWithAllowedDomain = (origin: string): boolean => !!ALLOWED_DOMAINS.find(s => origin.endsWith(s));

  const handleCrossDomainActivity = (event: MessageEvent<string>) => {
    // If no origin in the event, OR if the origin matches this document, just ignore it.
    if (!event?.origin || event.origin === origin) {
      return;
    }

    // Otherwise, check the domain to see if we recognize it.
    if (endsWithAllowedDomain(event.origin)) {
      LOG.info(`Cross-domain message received from ${event.origin}.`);
      if (event.data === 'PASSKEY_USER_ACTIVITY') {
        LOG.info('Dispatching mouse click to reset user activity timer...');
        const evt = new Event('click');
        document.body.dispatchEvent(evt);
      } else {
        LOG.debug(`Unknown passkey message ${JSON.stringify(event.data)}`);
      }
    } else {
      LOG.warn(`Cross domain message from ${event.origin} (${JSON.stringify(event.data)}) is not permitted.`);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleCrossDomainActivity, false);

    return () => {
      window.removeEventListener('message', handleCrossDomainActivity);
    };
  });

  const makeLogoutSessionReq = useCallback(
    () => executeLogout(),
    // We do not want to include executeLogout in the dependency list as it will result into
    // an infinite call to that hook. That needs to be ignored from eslint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const makeExtendSessionReq = useCallback(async () => {
    await extendSessionMutation({ variables: { action: SessionAction.REFRESH } });
    return EXTEND_SESSION_BY;
  }, [extendSessionMutation]);

  return (
    <SessionHandler
      showNoticeWhenUnder={props.keepAliveInterval || KEEP_ALIVE_INTERVAL}
      keepAliveInterval={props.keepAliveInterval || KEEP_ALIVE_INTERVAL}
      logOut={props.makeLogOut || makeLogoutSessionReq}
      makeSessionHandlerRequest={props.makeSessionHandlerRequest || makeExtendSessionReq}
      makeRefreshCsrfRequest={props.makeRefreshCsrfRequest || makeRefreshCsrfRequest}
      redirectToLogin={props.redirectToLogin || logOut}
      stillThereText={props.stillThereText || translate('_passkey_admin_session_still_there')}
      noticeText={props.noticeText || translate('_passkey_admin_session_notice')}
      logOutText={props.logOutText || translate('_passkey_admin_session_log_out')}
      keepWorkingText={props.keepWorkingText || translate('_passkey_admin_session_keep_working')}
      refreshErrorText={props.refreshErrorText}
      testID={props.refreshErrorText ? `admin-session-handler-${props.refreshErrorText}` : 'admin-session-handler'}
      zIndex={2000}
    >
      {props.children}
    </SessionHandler>
  );
}
