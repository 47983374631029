import React from 'react';
import { ThemeProvider as CarinaThemeProvider } from '@cvent/carina/components/ThemeProvider/ThemeProvider';
import { getDefaultTheme } from '@cvent/carina/utils/tokens';
import type { ReactNode } from 'react';

export function ThemeProvider({ children }: { children: ReactNode }): JSX.Element {
  return <CarinaThemeProvider theme={getDefaultTheme()}>{children}</CarinaThemeProvider>;
}

export default ThemeProvider;
